<template>
  <div class="mobile-input">
    <label
      class="mobile-input-wrap"
      :class="{'required':required, 'required-blue': !required && requiredBlue}"
    >
      <div class="mobile-input-wrap-title">{{ label | translate }}
        <button
          v-if="!!description"
          class="mobile-input-wrap-title-btn"
          @click="showHideDescription"
        >
          <img src="@/assets/images/icon_description.svg">
        </button>
      </div>
      <div
        class="mobile-input-wrap-input"
      >
        <span
          v-if="!!subLabel"
          class="mobile-input-wrap-input-sublabel"
        >{{ subLabel }}</span>
        <select
          v-model="internalValue"
          v-bind="{ ...$attrs }"
        >
          <option
            v-for="(item, index) in items"
            :key="item.key"
            :value="item.value"
            style="text-align: right"
          >
            {{ item.label }}
          </option>
        </select>
        <v-icon class="inner--icon">
          mdi-chevron-down
        </v-icon>
        <span
          v-if="!!unit"
          class="mobile-input-wrap-input-unit"
        >{{ unit | translate }}</span>
      </div>
    </label>
    <div
      v-if="descriptionOn"
      class="mobile-input-description"
    >
      {{ description }}
    </div>
  </div>
</template>

<script>
import MobileBaseInput from "./MobileBaseInput";

export default {
  name: "MobileSelect",
  extends: MobileBaseInput,
  props: {
    /**
     * {key, name, value}
     */
    items: {
      type: Array,
      default: () => []
    },
  },
}
</script>

<style lang="scss" scoped>
.inner--icon{
  position: absolute;
  top: 0.125rem;
  right: 0;
}
.mobile-input{
  font-size: 0.8rem;
  padding: 0.325rem 0.5rem;
  border-bottom: 1px solid #dddddd;
  &-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    &-title{
      display: flex;
      align-items: center;
      padding: 0.25rem;
      font-weight: normal;
      text-align: left;
      flex: 2;
      &-btn{
        color: #0c98fe;
        margin-left: 4px;
      }
        & img {
          display: flex;
          align-items: center;
        }
    }
    &-input{
      flex: 3;
      &-sublabel{
        overflow: auto;
        color: #6e6e6e;
        margin-right: 6px;
        &:after{
          content: ':';
        }
      }
      select{
        padding: 0.25rem 1.5rem 0.25rem 0.25rem ;
        border: 1px solid #dddddd;
        text-align: right;
        width: 100%;
        flex: 1;
        &:focus{
          outline: none;
        }
        &:disabled{
          background: #dddddd;
        }
      }
      &-unit{
        overflow: auto;
        color: #6e6e6e;
        margin-left: 6px;
      }
    }
    &.required:before{
      content: '*';
      color: #ff0000;
      position: absolute;
      left: -3px;
    }
    &.required-blue:before{
      content: '*';
      color: #0c98fe;
      position: absolute;
      left: -3px;
    }
  }

  &-description{
    font-size: 0.810rem;
    line-height: 1.2rem;
    color: #0c98fe;
    text-align: left;
    padding: 0.25rem 0.25rem;
    /*&:before{
      content: 'ⓘ';
    }*/
  }
}
</style>