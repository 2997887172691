<script>
    import MBaseFormPicker from "@/assets/plugins/mps-form/providers/MBaseFormPicker";
    import {PATH} from "@/constants";

    export default {
        name: "MFormStaffPicker",
        extends: MBaseFormPicker,
        data() {
            return {
                items: [],
                inLoading: false,
                innerData: null,
            }
        },
        computed: {
            displayable() {
                // items 가 없으면 표시 불가능
                if (this.$isEmpty(this.items)) return false;
                return true;
            },
            filteredItems() {
                return this.items;
            },
            modelSafe() {
                return this.model || {};
            },
        },
        watch: {
            model() {
                this.innerData = this.modelSafe.userId;
            },
            innerData() {
                if (this.$isEmpty(this.filteredItems)) return;
                this.model = this.filteredItems.find(item => item.userId === this.innerData);
            },
            inEditMode() {
                if (this.inEditMode) {
                    this.innerData = this.modelSafe.userId;
                }
            },
        },
        created() {
            this.innerData = this.modelSafe.userId;
            this.loadItems();
        },
        methods: {
            async loadItems() {
                this.inLoading = true;
                try {
                    const response = await this.$request(PATH.STAFF_EMPLOYEES_LIST)
                        .post()
                        .enqueue();
                    this.items = response.data;
                    this.items.push({
                      userId: null,
                      userFullname: '선택안함'
                    })
                } catch (e) {
                    this.items = undefined;
                }
                this.inLoading = false;
            }
        },
    }
</script>
