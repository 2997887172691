<template>
  <div class="mobile-input">
    <label
      class="mobile-input-wrap"
      :class="{'required':required}"
    >
      <div class="mobile-input-wrap-title">{{ label | translate }}
        <button
          v-if="!!description"
          class="mobile-input-wrap-title-btn"
          @click="showHideDescription"
        >
          <img src="@/assets/images/icon_description.svg">
        </button>
      </div>
      <div
        class="mobile-input-wrap-input"
      >
        <div
          v-for="(item, index) in internalValue"
          :key="index"
          class="mb-1 mr-2"
        >
          <button
            class="attachment_file_button"
            @click.stop="onClick(item)"
          >
            <template
              v-if="buttonType === 'file'"
            >
              <template v-if="isImageFile(item.path)">
                <img
                  :src="getImagePath(item.path)"
                  class="ma-auto"
                  style="cursor: pointer; max-width: 100%;"
                >
              </template>
              <div
                class="attachment_file_name"
              >
                {{ item.originalFilename }}
                <v-icon>mdi-download</v-icon>
              </div>
              <!-- Image 파일인 경우 미리보기 -->
            </template>
            <template
              v-if="buttonType === 'link'"
            >
              <div
                class="attachment_file_name"
              >
                {{ '보러가기' | translate }}
              </div>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </button>
        </div>
        <div v-if="(!internalValue || internalValue.length < 1) && buttonType === 'link'">
          <button
            class="attachment_file_button"
            @click="onClick()"
          >
            <template
              v-if="buttonType === 'link'"
            >
              <div
                class="attachment_file_name"
              >
                {{ '생성하기' | translate }}
              </div>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </button>
        </div>
      </div>
    </label>
    <div
      v-if="descriptionOn"
      class="mobile-input-description"
    >
      {{ description }}
    </div>
  </div>
</template>

<script>
    import MobileBaseInput from "./MobileBaseInput";
    import {PATH} from "@/constants";

    export default {
        name: "MobileButton",
        extends: MobileBaseInput,
        props: {
            value: {
                type: Array,
                default: () => []
            },
            buttonType: {
                type: String,
                default: 'file'
            },
        },
      data() {
        return {
          image: undefined,
        }
      },
      created() {

      },
        methods: {
            onClick(e) {
                this.$emit('onClick', e)
            },
            isImageFile(path) {
              return this.fileManager.isImageFile(path);
            },
            getImagePath(path){
              return `${PATH.BASE_URL}${PATH.RESOURCE.FILE}?path=${encodeURI(
                  path
              )}&filename=${encodeURI(path)}`
            },
        }
    }
</script>

<style lang="scss" scoped>
    .mobile-input {
        font-size: 0.8rem;
        padding: 0.325rem 0.5rem;
        border-bottom: 1px solid #dddddd;

        &-wrap {
            display: flex;
            flex-direction: column;
            align-items: start;
            position: relative;

            &-title {
                display: flex;
                padding: 0.25rem;
                font-weight: normal;
                text-align: left;
                flex: 2;
                margin-bottom: 8px;

                &-btn {
                    color: #0c98fe;
                    margin-left: 4px;
                }
            }

            &-input {
                flex: 3;
                display: flex;
                align-items: center;
                width: 100%;
                overflow-x: auto;

                div {
                    flex: 1;
                    max-width: 240px;
                }
            }

            &.required:before {
                content: '*';
                color: #ff0000;
                position: absolute;
                left: -3px;
            }

            &.required-blue:before {
                content: '*';
                color: #0000ff;
                position: absolute;
                left: -3px;
            }
        }

        &-description {
            font-size: 0.810rem;
            line-height: 1.2rem;
            color: #0c98fe;
            text-align: left;
            padding: 0 0.25rem;

            /*&:before {
                content: 'ⓘ';
            }*/
        }
    }

    .attachment_file_button {
        width: 100%;
        min-width: 130px;
        flex: 1;
        align-items: center;
        text-align: left;
        background-color: #f5f5f5;
        padding: 6px 8px;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    }

    .attachment_file_name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
</style>