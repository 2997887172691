<template>
  <div class="mobile-input">
    <label
      class="mobile-input-wrap"
      :class="{'required':required, 'required-blue': !required && requiredBlue}"
    >
      <div
        class="mobile-input-wrap-title"
      >
        {{ label | translate }}
        <button
          v-if="!!description"
          class="mobile-input-wrap-title-btn"
          @click="showHideDescription"
        >
          <img src="@/assets/images/icon_description.svg">
        </button>
      </div>
      <div
        class="mobile-input-wrap-input"
      >
        <span
          v-if="!!subLabel"
          class="mobile-input-wrap-input-sublabel"
        >{{ subLabel | translate }}</span>
        <input
          ref="mobile-input-con"
          v-model="computedInternalValue"
          v-bind="{...castAttr}"
          @focus="onFocusIn"
        >
        <span
          v-if="!!unit"
          class="mobile-input-wrap-input-unit"
        >{{ unit | translate }}</span>
      </div>
    </label>
    <div
      v-if="descriptionOn"
      class="mobile-input-description"
    >
      {{ description }}
    </div>
  </div>
</template>

<script>
import MobileBaseInput from "./MobileBaseInput";
import number from "../../../assets/plugins/mps-common/expansions/internal/number";

export default {
  name: "MobileInput",
  extends: MobileBaseInput,
  props:{
    min: {
      type: Number,
      default: undefined,
    },
    max: {
      type: Number,
      default: 999999999,
    },
  },
  computed: {
    castAttr(){
      const {type, ...etcAttrs} = this.$attrs

      return type === 'number' ? etcAttrs : this.$attrs
    },

    computedInternalValue: {
      get() {
        const {type} = this.$attrs
        if(type === 'number'){
          try {
            return Number(this.internalValue | 0).toLocaleString()
          } catch (e) {
            return typeof this.min === 'undefined' ? 0 : this.min
          }
        } else {
          return this.internalValue
        }
      },

      set(newValue) {
        const {type} = this.$attrs
        let setValue = newValue
        if(type === 'number' && typeof setValue !== 'undefined'){
          this.internalValue = 0
          try {
            setValue = Number(setValue.replace(/[^0-9]/g, ''))
          } catch (e) {
            setValue = typeof this.min === 'undefined' ? 0 : this.min
          }
          if(typeof this.min !== 'undefined' && this.min > setValue)
            setValue = this.min
          if (typeof this.max !== 'undefined' && this.max < setValue)
            setValue = this.max
        }
        this.internalValue = setValue
      },
    },
  },
  methods: {
    onFocusIn() {
      this.$nextTick(() => {
        const temp = this.computedInternalValue.length
        const target = this.$refs["mobile-input-con"]
        target.setSelectionRange(0, temp)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.mobile-input{
  font-size: 0.8rem;
  padding: 0.325rem 0.5rem;
  border-bottom: 1px solid #dddddd;
  &-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    &-title{
      display: flex;
      align-items: center;
      padding: 0.25rem;
      font-weight: normal;
      text-align: left;
      flex: 2;
      &-btn{
        color: #0c98fe;
        margin-left: 4px;
      }
        & img {
          display: flex;
          align-items: center;
        }
    }
    &-input{
      display: flex;
      align-items: center;
      flex: 3;
      &-sublabel{
        overflow: auto;
        color: #6e6e6e;
        margin-right: 6px;
        &:after{
          content: ':';
        }
      }
      input{
        padding: 0.25rem 1rem 0.25rem 0.25rem;
        border: 1px solid #dddddd;
        text-align: right;
        width: 100%;
        flex: 1;
        &:focus{
          outline: none;
        }
        &:disabled{
          background: #dddddd;
        }
      }
      &-unit{
        overflow: auto;
        color: #6e6e6e;
        margin-left: 6px;
      }
    }
    &.required:before{
      content: '*';
      color: #ff0000;
      position: absolute;
      left: -3px;
    }
    &.required-blue:before{
      content: '*';
      color: #0c98fe;
      position: absolute;
      left: -3px;
    }
  }
  &-description{
    font-size: 0.810rem;
    line-height: 1.2rem;
    color: #0c98fe;
    text-align: left;
    padding: 0.25rem 0.25rem;
    /*&:before{
      content:url(/img/icon_description.svg);
    }*/
  }
}
</style>