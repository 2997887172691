import AsSuccessVuetifyModal from "@/components/vuetify-modal/as/AsSuccessVuetifyModal";
import AsCancelVuetifyModal from "@/components/vuetify-modal/as/AsCancelVuetifyModal";
import AsDenyVuetifyModal from "@/components/vuetify-modal/as/AsDenyVuetifyModal";
import AsReserveVuetifyModal from "@/components/vuetify-modal/as/AsReserveVuetifyModal";
import StaffAssignVuetifyModal from "@/components/vuetify-modal/as/StaffAssignVuetifyModal";
import StaffDelegateVuetifyModal from "@/components/vuetify-modal/as/StaffDelegateVuetifyModal";
import AsSearchFilterModal from "@/components/vuetify-modal/filter/AsSearchFilterModal";
import AccountSearchFilterModal from "@/components/vuetify-modal/filter/AccountSearchFilterModal";
import DeviceSearchFilterModal from "@/components/vuetify-modal/filter/DeviceSearchFilterModal";
import PrinterSearchFilterModal from "@/components/vuetify-modal/filter/PrinterSearchFilterModal";
import MFormAccountFullModal from "@/components/vuetify-modal/form/MFormAccountFullModal";
import MFormAddressFullModal from "@/components/vuetify-modal/form/MFormAddressFullModal";
import MFormBranchFullModal from "@/components/vuetify-modal/form/MFormBranchFullModal";
import MFormPrinterFullModal from "@/components/vuetify-modal/form/MFormPrinterFullModal";
import MFormRentalcompanyCodeFullModal from "@/components/vuetify-modal/form/MFormRentalcompanyCodeFullModal";
import RentalUserFindIdModal from "@/components/vuetify-modal/find/RentalUserFindIdModal";
import RentalUserFindPasswordModal from "@/components/vuetify-modal/find/RentalUserFindPasswordModal";
import PrinterInstallLogAddModal from "@/components/vuetify-modal/PrinterInstallLog/PrinterInstallLogAddModal";
import ElectricalContractSignModal from "@/components/new-comp/modal/ElectricalContractSignModal";
import ElectricalContractSendMailModal from "@/components/new-comp/modal/ElectricalContractSendMailModal";
import printerDailyUsageFilterModal from "@/components/vuetify-modal/filter/PrinterDailyUsageFilterModal";
import RetiredModal from "../components/vuetify-modal/member/RetiredModal";

const MODAL_VUETIFY_NAMES = {
    /**
     * 작업 다이얼로그
     */
    AS: {
        SUCCESS: "AS_SUCCESS",
        CANCEL: "AS_CANCEL",
        DENY: "AS_DENY",
        RESERVE: "AS_RESERVE",
        ASSIGN: "AS_ASSIGN",
        DELEGATE: "AS_DELEGATE",
    },

    /**
     * 목록 필터
     */
    FILTER: {
        AS: "FILTER_AS",
        ACCOUNT: "FILTER_ACCOUNT",
        DEVICE: "FILTER_DEVICE",
        PRINTER: "FILTER_PRINTER",
        PRINTER_DAILY: "FILTER_PRINTER_DAILY",
    },

    /**
     * 폼 다이얼로그
     */
    FORM: {
        PICKER_ACCOUNT: "FORM_PICKER_ACCOUNT",
        PICKER_ADDRESS: "FORM_PICKER_ADDRESS",
        PICKER_BRANCH: "FORM_PICKER_BRANCH",
        PICKER_PRINTER: "FORM_PICKER_PRINTER",
        PICKER_RENTALCOMPANY_CODE: "FORM_PICKER_RENTALCOMPANY_CODE",
    },

    FIND: {
        RENTAL_USER_ID: "RENTAL_USER_ID",
        RENTAL_USER_PASSWORD: "RENTAL_USER_PASSWORD",
    },
    /**
     * 설치이력 추가 or 수정
     * */
    PRINTER_INSTALL_LOG: {
        ADD_MODIFY: "PRINTER_INSTALL_LOG_ADD_MODIFY"
    },

    /**
     * 전자계약서
     */
    ELECTRICAL_CONTRACT: {
        SIGN: 'ELECTRICAL_CONTRACT_SIGN',
        SEND_MAIL: 'ELECTRICAL_CONTRACT_SEND_MAIL'
    },

    MEMBER: {
        RETIRED: 'MEMBER_RETIRED'
    }
};

const MODAL_VUETIFY_COMPONENTS = [
    /** AS START */
    {name: MODAL_VUETIFY_NAMES.AS.SUCCESS, component: AsSuccessVuetifyModal},
    {name: MODAL_VUETIFY_NAMES.AS.CANCEL, component: AsCancelVuetifyModal},
    {name: MODAL_VUETIFY_NAMES.AS.DENY, component: AsDenyVuetifyModal},
    {name: MODAL_VUETIFY_NAMES.AS.RESERVE, component: AsReserveVuetifyModal},
    {name: MODAL_VUETIFY_NAMES.AS.ASSIGN, component: StaffAssignVuetifyModal},
    {name: MODAL_VUETIFY_NAMES.AS.DELEGATE, component: StaffDelegateVuetifyModal},
    /** AS END */

    /** FILTER START */
    {name: MODAL_VUETIFY_NAMES.FILTER.AS, component: AsSearchFilterModal},
    {name: MODAL_VUETIFY_NAMES.FILTER.ACCOUNT, component: AccountSearchFilterModal},
    {name: MODAL_VUETIFY_NAMES.FILTER.DEVICE, component: DeviceSearchFilterModal},
    {name: MODAL_VUETIFY_NAMES.FILTER.PRINTER, component: PrinterSearchFilterModal},
    {name: MODAL_VUETIFY_NAMES.FILTER.PRINTER_DAILY, component: printerDailyUsageFilterModal},
    /** FILTER END */

    /** FORM START */
    {name: MODAL_VUETIFY_NAMES.FORM.PICKER_ACCOUNT, component: MFormAccountFullModal},
    {name: MODAL_VUETIFY_NAMES.FORM.PICKER_ADDRESS, component: MFormAddressFullModal},
    {name: MODAL_VUETIFY_NAMES.FORM.PICKER_BRANCH, component: MFormBranchFullModal},
    {name: MODAL_VUETIFY_NAMES.FORM.PICKER_PRINTER, component: MFormPrinterFullModal},
    {name: MODAL_VUETIFY_NAMES.FORM.PICKER_RENTALCOMPANY_CODE, component: MFormRentalcompanyCodeFullModal},
    /** FORM END */

    /** Find Modal START */
    {name: MODAL_VUETIFY_NAMES.FIND.RENTAL_USER_ID, component: RentalUserFindIdModal},
    {name: MODAL_VUETIFY_NAMES.FIND.RENTAL_USER_PASSWORD, component: RentalUserFindPasswordModal},
    /** Find Modal END */

    /** PRINTER_INSTALL_LOG START*/
    {name: MODAL_VUETIFY_NAMES.PRINTER_INSTALL_LOG.ADD_MODIFY, component: PrinterInstallLogAddModal},

    /** ElectricalContract */
    {name: MODAL_VUETIFY_NAMES.ELECTRICAL_CONTRACT.SIGN, component: ElectricalContractSignModal},
    {name: MODAL_VUETIFY_NAMES.ELECTRICAL_CONTRACT.SEND_MAIL, component: ElectricalContractSendMailModal},

    /** MEMBER */
    {name: MODAL_VUETIFY_NAMES.MEMBER.RETIRED, component: RetiredModal}
];

export {MODAL_VUETIFY_COMPONENTS, MODAL_VUETIFY_NAMES};