import {get, put} from '@/assets/plugins/mps-common/app/local-storage-helper'
import {isNOU} from "../expansions/condition";
import {dateHelper} from "@/assets/plugins/mps-common/time/date-helper";
import {PATH} from "@/constants";
import VueAccessor from "../app/VueAccessor";
import {permissionCanonicalNameManager} from "@/assets/plugins/mps-common/user/permission-canonical-name-manager";
import {translationManager} from "@/assets/plugins/mps-common/translation/translation-manager";
import {nativeBridge} from "@/assets/plugins/mps-mobile-native-bridge/native-bridge";
import {pref} from "@/assets/plugins/mps-common/preferences/preferences";
import Permissions from "@/constants/permissions";
import {SORT_USER} from "@/constants/etc-code";

const KEY_ACCESS_TOKEN = 'key_access_token'
const KEY_REFRESH_TOKEN = 'key_refresh_token'
const KEY_USER = 'key_user';

/**
 * MPS USER 를 위한 관리자 입니다.
 * 로그인 정보, 유저 정보, 및 API 접근 토큰 관리자입니다.
 * 어플리케이션 내에서 한개만 유지되어야 합니다.
 */
class UserManager extends VueAccessor {

    _accessToken;
    _refreshToken
    _firebaseToken;
    // JWT 토큰 재발급 요청 상태
    // (어떤 request가 이미 재발급 요청을 보낸 상태)
    _isTokenRefreshing
    // 로그인 허가 여부
    _isLoginApproved
    _user = {
        countryCode: undefined,
        languageCode: undefined,
        name: undefined,
        canonicalNameList: [],
        rentalcompanyId: undefined,
        rentalcompanyName: undefined,
        roles: [],
        userId: undefined,
        isAdmin: undefined,
        isWorker: undefined,
    };
    _permissionCanonicalNameManager;
    _nativeBridge;
    _sort;
    _beLogout = false;

    constructor() {
        super();
        this._permissionCanonicalNameManager = permissionCanonicalNameManager;
        this._restorePersistenceData();
        if (!!this.user) nativeBridge.putUser(this.user.userId, this.accessToken);
    }

    /**
     * 영속 데이터를 복구합니다.
     * @private
     */
    _restorePersistenceData() {
        this.accessToken = pref.get(KEY_ACCESS_TOKEN, null)
        this.refreshToken = pref.get(KEY_REFRESH_TOKEN, null)
        this.isLoginApproved = true
        // this.accessToken = get(KEY_ACCESS_TOKEN, null);
        this.user = get(KEY_USER, null);
        this.firebaseToken = pref.get('fcm_token', '');
        // 사용자 로그인 상태면 권한을 복구해서 권한 매니저에 설정합니다.
        if (!!this.user) {
            // 권한이 있습니다. 복구.
            if (!!this.user.canonicalNameList) {
                this._permissionCanonicalNameManager.setPermissions(this.user.canonicalNameList);
                if (this.hasPermission(Permissions.RENTAL_ADMIN)) {
                    this._user.isAdmin = true;
                } else if (this.hasPermission(Permissions.RENTAL_WORKER)) {
                    this._user.isWorker = true;
                }
            }
            // 권한이 없습니다. 유저 정보를 제거합니다.
            else {
                // this.user = null;
            }
            this._sort = !!this._user.rentalcompanyId ? SORT_USER.RENTALCOMPANY : SORT_USER.COMMUNITY;
            // 유저 정보를 네이티브에 전달
            nativeBridge.putUser(this.user.userId, this.accessToken);
        }
        // 사용자 로그인 상태가 아니면
        else {
            this.sendTokenToServer();
        }
    }

    set nativeBridge(nativeBridge) {
        this._nativeBridge = nativeBridge;
    }

    set accessToken(accessToken) {
        this._accessToken = accessToken;
        pref.put(KEY_ACCESS_TOKEN, accessToken);
    }

    get isCommunityUser() {
        return this._sort === SORT_USER.COMMUNITY;
    }

    get isRentalUser() {
        return this._sort === SORT_USER.RENTALCOMPANY;
    }

    get accessToken() {
        return this._accessToken;
    }

    set refreshToken(refreshToken) {
        this._refreshToken = refreshToken
        pref.put(KEY_REFRESH_TOKEN, refreshToken)
    }

    get refreshToken() {
        return this._refreshToken
    }

    set isTokenRefreshing(value) {
        this._isTokenRefreshing = value
    }

    get isTokenRefreshing() {
        return this._isTokenRefreshing
    }

    set isLoginApproved(value) {
        this._isLoginApproved = value
    }

    get isLoginApproved() {
        return this._isLoginApproved
    }

    get firebaseToken() {
        return this._firebaseToken;
    }

    set firebaseToken(value) {
        this._firebaseToken = value;
    }

    get locale() {
        return (this.user.languageCode || "").toLowerCase() + "-" + (this.user.countryCode || "").toUpperCase();
    }

    get isKorean() {
        return this.user.countryCode === "KR";
    }

    get isForeign() {
        return !this.isKorean;
    }

    set user(user) {
        this._user = user;
        put(KEY_USER, user);
        if (!!user) {
            dateHelper.setUser(user);
        }
    }

    get user() {
        return this._user;
    }

    get hasAccessToken() {
        return this.accessToken !== null;
    }

    get authorization() {
        return this.hasAccessToken ? 'Bearer ' + this.accessToken : null;
    }

    get validate() {
        return !(isNOU(this.accessToken) || isNOU(this.refreshToken) || isNOU(this.user));
    }

    hasPermission(canonicalPermissionName) {
        return this._permissionCanonicalNameManager.hasPermission(canonicalPermissionName);
    }

    async login(rentalCompanyId, loginId, password) {
        const request = this.vm.$request(PATH.USER_LOGIN)
            .setObject({
                rentalcompanyId: rentalCompanyId.trim(),
                loginId: loginId.trim(),
                password: password.trim(),
                mobileYn: 'Y'
            });
        return new Promise(async (resolve, reject) => {
            try {
                const response = await request.enqueue();
                this.isLoginApproved = true
                this.accessToken = response.accessToken
                this.refreshToken = response.refreshToken
                this.user = response.user;
                this._permissionCanonicalNameManager.setPermissions(this.user.canonicalNameList); // 사용자 권한
                if (this.hasPermission(Permissions.RENTAL_ADMIN)) {
                    this._user.isAdmin = true;
                } else if (this.hasPermission(Permissions.RENTAL_WORKER)) {
                    this._user.isWorker = true;
                }
                translationManager.save(response.languages);
                // 유저 정보를 네이티브에 전달
                nativeBridge.putUser(this.user.userId, this.accessToken);
                this._sort = SORT_USER.RENTALCOMPANY;
                resolve(response);
            } catch (e) {
                this._sort = null;
                console.log(e);
                reject(e);
            }
        });
    }

    async communityLogin(loginId, password) {
        const request = this.vm.$request(PATH.COMMUNITY_USER.LOGIN)
            .setObject({
                email: loginId.trim(),
                password: password.trim()
            })
            .catch();
        return new Promise(async (resolve, reject) => {
            try {
                const response = await request.enqueue();
                this.isLoginApproved = true
                this.accessToken = response.accessToken
                this.refreshToken = response.refreshToken
                this.vm.$set(this, 'user', {});
                this.user = response.communityUser;
                // translationManager.save(response.languages);
                // 유저 정보를 네이티브에 전달
                // nativeBridge.putUser(this.user.userId, this.accessToken);
                this._sort = SORT_USER.COMMUNITY;
                resolve(response);
            } catch (e) {
                this._sort = null;
                console.error(e);
                reject(e);
            }
        });
    }

    async sendTokenToServer() {
        if (!this.user || !this.user.userId) return;
        try {
            const res = await this.vm.$request("user/fcm/reg")
                .setObject({
                    token: this.firebaseToken,
                    deviceType: 'MOBILE:APP',
                    deviceId: this.deviceId,
                    telNo: ''
                })
                .catch()
                .enqueue();
            console.log(res);
        } catch (e) {
            console.error(e);
            //do nothing.
        }
    }

    async logout() {
        return new Promise((resolve, reject) => {
            this.accessToken = null;
            this.refreshToken = null
            this.user = null;
            this.beLogout = true;
            // this._permissionCanonicalNameManager.clearPermissions();
            this.vm.routerManager.push({name: 'LogoutDivider'});
            // 네이티브에 정보 초기화 하기.
            nativeBridge.putUser(null, null);
            // 완료
            resolve();
        });
    }

    /**
     * 로그아웃 처리가 되어서 라우터가 이동되었는지 여부를 나타내기 위한 플래그
     */
    get beLogout() {
        return this._beLogout;
    }

    /**
     * 로그아웃시 true 로 변경 router beforeEach에서 적절한 처리후 false로 변경한다.
     */
    set beLogout(beLogout) {
        this._beLogout = beLogout;
    }
};

const userManager = new UserManager();

export default userManager;
